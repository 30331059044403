<template>
  <loader v-bind="{ loading }" text="Loading Locations">
    <columns>
      <column>
        <page-heading
          heading="Work Orders"
          sub-heading="Work Orders allow you to group 1 or more checklists together."
        />
      </column>
      <column class="is-5">
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search work orders...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="isFiltering"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
                <action-button @click="filtering = !filtering">
                  <icon icon="sliders"/>
                </action-button>
                <router-link 
                  v-if="!$root.isPassiveUser()"
                  class="button is-rounded is-flex" 
                  :to="{
                    name: 'create-work-order'
                  }">
                  <icon icon="plus"/>
                  <span>Work Order</span>
                </router-link>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>

    <columns>
      <column>
        <action-button :working="downloading" @click="downloadExcel" left-icon="download" class="is-small is-rounded">
          Excel
        </action-button>
      </column>
    </columns>

    <Transition name="fade" mode="out-in">
      <work-order-filters
        class="mb-3"
        v-show="filtering"
        ref="filters"
        @filter="updateAdvancedFilters"
      />
    </Transition>

    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="workOrder in workOrders.data" :key="workOrder.uuid">
            <columns>
              <column>
                <router-link class="is-block" :to="{
                  name: 'work-order-manager',
                  params: {
                    workOrder: workOrder.uuid
                  }
                }">
                  #{{ workOrder.number }}
                  {{ workOrder.reference }}
                  <icon 
                    icon="rotate" 
                    class="has-text-warning" 
                    v-tooltip="'Created from a Recurring Work Order'"
                    v-if="workOrder.schedule"
                  />
                  <span v-if="jobIsOverdue(workOrder)" class="has-text-danger" v-tooltip="`This work order is passed due.`">
                    <icon icon="stopwatch"/>
                  </span>
                </router-link>
                <small class="has-text-grey">
                  For {{ workOrder.customer.name }} at {{ workOrder.location.name }}, 
                  Updated <span
                    v-tooltip="formatDate(workOrder.updated_at)">{{ workOrder.updated_at | asCalendar }}</span>
                </small>
              </column>
              <column class="is-narrow is-flex is-align-items-center is-justify-content-end">
                <span v-tooltip="'The team assigned to this Work Order'">
                  <icon icon="people-arrows" class="has-text-grey mr-1"/>
                  <span>{{ workOrder.team.name }}</span>
                </span>
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <circular-progress-bar 
                  tooltip="Current progress of the Work Order"
                  :total="workOrder.inspections.length"
                  :total-complete="workOrder.inspections.map(inspection => inspection.closed_off_at).length"
                  backgroundColor="#212127"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-center">
                <priority-indicator 
                  :priority="workOrder.priority"
                />
              </column>
              <column class="is-narrow is-flex is-align-items-center is-justify-content-end">
                <outcome-tag 
                    :status="workOrder.status"
                />
              </column>
            </columns>
          </div>
        </div>
      </column>
    </columns>
    <pager 
      v-if="workOrders.data.length" 
      :pageable="workOrders" 
      context="Work Order" 
      jump-controls @nav="goToPage"
    />

    <no-items-to-display
      :item-count="workOrders.data.length"
      with-link
      heading="There are no work orders available for display."
      :to="{
        name: 'create-work-order'
      }"
      to-text="New Work Order"
    />
  </loader>
</template>
<script>
import { get } from '@/api/request'
import moment from 'moment'
import WorkOrderFilters from './partials/Filters.vue'
import { common as backend } from '@/api'
import download from 'downloadjs'

const base_filters = {
  search: ''
}

export default {

  components: {
    WorkOrderFilters
  },

  data: () => ({
    loading: true,
    filtering: false,
    downloading: false,
    filters: base_filters,
    workOrders: {
      data: []
    }
  }),

  created() {
    if(this.$route.query) {
      this.filters = { ...this.$route.query, ...this.filters }
    }
    this.loadworkOrders()
  },

  methods: {
    loadworkOrders() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.workOrders = data
        this.loading = false
      }, () => {
        this.loading = false
      }, { params: this.filters })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'work-orders',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters = base_filters
      this.$refs.filters.clearFilters()
      this.$router.push({
        name: 'work-orders',
      });
    },
    jobIsOverdue(workOrder) {
      if(!workOrder.ending_at) return false
      return moment(workOrder.ending_at).isBefore(moment()) && !workOrder.closed_off_at
    },
    updateAdvancedFilters(filters) {
      this.filters = { ...this.filters, ...filters }
    },
    downloadExcel() {
      this.downloading = true
      backend.downloadFile(`/v1/work-orders/download-xls`, ({ data }) => {
        download(data, `work-orders.xlsx`, 'xlsx')
        this.downloading = false
      }, () => {
        this.downloading = false
      }, this.filters)
    },
    formatDate(date) {
      return moment(date).format('Y-MM-DD HH:mm:ss')
    }
  },

  computed: {
    isFiltering() {
      return Object.keys(this.$route.query).length > 0
    }
  },

  watch: {
    '$route': 'loadworkOrders'
  }

}
</script>