<template>
<div>
    <columns>
        <column>
            <data-selector 
                multiple
                searchable
                :items="customer_list"
                value-key="id"
                label-key="name"
                v-model="filters.customers" 
                classes="is-rounded" 
                prompt-label="Filter Customers">
                Customers
            </data-selector>
        </column>
        <column v-if="!$root.isPassiveUser()">
            <data-selector 
                multiple
                searchable
                v-model="filters.teams" 
                classes="is-rounded" 
                prompt-label="Filter Teams"
                value-key="id"
                label-key="name"
                :items="team_list">
                    Teams
                </data-selector>
        </column>
        <column>
            <select-input v-model="filters.status" :items="statuses" classes="is-rounded">
                Status
            </select-input>

        </column>
        <column class="is-narrow">
            <select-input 
                v-model="filters.priority" 
                classes="is-rounded" 
                :items="priorities"
                value-key="id"
                label-key="level">
                Priority
            </select-input>
        </column>
        <column class="is-narrow is-flex is-align-items-end">
            <checkbox-input v-model="filters.overdue" inner-label="Overdue" />
        </column>
    </columns>

    <columns>
        <column v-if="!$root.isPassiveUser()" class="is-3">
            <data-selector 
                multiple
                searchable
                :items="branch_list"
                value-key="id"
                label-key="name"
                v-model="filters.branches" 
                classes="is-rounded" 
                placeholder="Filter Branches">
                Branches
            </data-selector>    
        </column>
        <column class="is-3">
            <data-selector 
                searchable
                :items="work_order_classifications"
                value-key="id"
                label-key="title"
                v-model="filters.classification" 
                classes="is-rounded" 
                placeholder="Filter Classifications">
                Classification
            </data-selector>    
        </column>
        <column class="is-3">
            <date-picker 
                v-model="filters.start_date" 
                classes="is-rounded" 
                placeholder="Start Date">
                Start Date
            </date-picker>    
        </column>
        <column class="is-3">
            <date-picker 
                v-model="filters.end_date" 
                classes="is-rounded" 
                placeholder="End Date">
                End Date
            </date-picker>    
        </column>
    </columns>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { priority as backend } from '@/api'

const base_filters = {
    customers: [],
    branches: [],
    teams: [],
    status: '',
    priority: '',
    classification: '',
    overdue: false
}

export default {

    data: () => ({
        filters: base_filters,
        priorities: []
    }),

    async created() {
        await this.$store.dispatch('customer/loadCustomerList')
        await this.$store.dispatch('branch/loadList')
        await this.$store.dispatch('team/loadTeamList')
        await this.$store.dispatch('workOrderClassification/loadWorkOrderClassificationList')
        await backend.loadPriorityList(({ data }) => this.priorities = data)
        let { customers, branches, teams, status, priority, overdue } = this.$route.query
        if (customers && customers.length) {
            this.filters.customers = this.customer_list.filter(customer => customers.includes(customer.id))
        }
        if (teams && teams.length) {
            this.filters.teams = this.team_list.filter(team => teams.includes(team.id))
        }
        if (branches && branches.length) {
            this.filters.branches = this.branch_list.filter(branch => branches.includes(branch.id))
        }
        this.filters.status = status || ''    
        this.filters.priority = priority || ''
        this.filters.overdue = overdue && overdue === 'true' ? true : false
    },

    methods: {
        emitFilters() {
            let filters = {...this.filters}
            filters.customers = filters.customers.map(customer => customer.id)
            filters.teams = filters.teams.map(team => team.id)
            filters.branches = filters.branches.map(branch => branch.id)
            this.$emit('filter', filters)
        },
        clearFilters() {
            this.filters = base_filters
        }
    },

    computed: {
        statuses() {
            return [
                { value: 'failed', label: 'Failed' },
                { value: 'passed', label: 'Passed' },
                { value: 'in progress', label: 'In Progress' },
                { value: 'complete', label: 'Complete' },
                { value: 'scheduled', label: 'Scheduled' },
            ]
        },
        ...mapGetters('customer', ['customer_list']),
        ...mapGetters('team', ['team_list']),
        ...mapGetters('branch', [
            'branch_list'
        ]),
        ...mapGetters('workOrderClassification', [
            'work_order_classifications'
        ]),
    },

    watch: {
        'filters.customers': 'emitFilters',
        'filters.branches': 'emitFilters',
        'filters.teams': 'emitFilters',
        'filters.status': 'emitFilters',
        'filters.priority': 'emitFilters',
        'filters.overdue': 'emitFilters',
        'filters.classification': 'emitFilters',
        'filters.start_date': 'emitFilters',
        'filters.end_date': 'emitFilters',
    }
    

}
</script>